<template>
  <div>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <date-time-picker
              @getDateFrom="dateFrom = $event"
              @getDateTo="dateTo = $event"
            >
            </date-time-picker>
            <v-row
              v-if="!$checkRole('ADVERTISERS') && !$checkRole('ADVERTISERS_USERS') && !$checkRole('PROVIDERS')"
            >
              <v-col
                cols="6"
                xl="6"
                md="6"
                sm="12"
              >
                <v-autocomplete
                  v-model="advertiserId"
                  :items="advertisers"
                  item-text="name"
                  item-value="id"
                  :label="$t('Advertiser')"
                  :disabled="loadingFirst"
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="1"
                xl="1"
                md="1"
                sm="1"
              >
                <v-progress-circular
                  v-if="loadingFirst"
                  class="mt-5"
                  indeterminate
                  color="primary"
                  :width="2"
                  :size="20"
                ></v-progress-circular>
              </v-col>
            </v-row>
            <v-row
              v-if="$checkRole('PROVIDERS')"
            >
              <v-col
                cols="6"
                xl="6"
                md="6"
                sm="12"
              >
                <v-autocomplete
                  v-model="providerId"
                  :items="providers"
                  item-text="name"
                  item-value="id"
                  :label="$t('Provider')"
                  :disabled="loadingFirst"
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="1"
                xl="1"
                md="1"
                sm="1"
              >
                <v-progress-circular
                  v-if="loadingFirst"
                  class="mt-5"
                  indeterminate
                  color="primary"
                  :width="2"
                  :size="20"
                ></v-progress-circular>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="6"
                xl="6"
                md="6"
                sm="12"
              >
                <v-autocomplete
                  ref="camp"
                  v-model="campaignId"
                  :items="campaigns"
                  item-text="name"
                  item-value="id"
                  :label="$t('CampaignFilter')"
                  :disabled="loadingCampaign"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="1"
                xl="1"
                md="1"
                sm="1"
              >
                <v-progress-circular
                  v-if="loadingCampaign"
                  class="mt-5"
                  indeterminate
                  color="primary"
                  :width="2"
                  :size="20"
                ></v-progress-circular>
              </v-col>
            </v-row>
            <v-btn
              class="mt-5"
              color="primary"
              @click="getData"
            >
              {{ $t('BtnSearch') }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="w-full">
          <v-card-text>
            <campaign-creatives-chart
              :data="dataFromDb"
            >
            </campaign-creatives-chart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="dataFromDb"
          item-key="id"
          class="elevation-1"
          :search="search"
          :loading="loading"
          :locale="$i18n.locale"
        >
          <template
            v-slot:top
          >
            <div class="row">
              <div class="col-8">
                <div class="container">
                  <v-btn
                    v-if="$checkAbility('CAN_DOWNLOAD_REPORT')"
                    outlined
                    color="primary"
                    @click="exportToExcel()"
                  >
                    {{ $t('VtrReportElements.Download') }}
                  </v-btn>
                </div>
              </div>
              <div class="col-4">
                <v-text-field
                  v-model="search"
                  :label="$t('LblSearch')"
                  class="mx-4"
                ></v-text-field>
              </div>
            </div>
          </template>
          <template #[`item.id`]="{item}">
            <v-row>
              <v-col>
                <v-menu
                  top
                  offset-x
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <template>
                      <v-list-item
                        :to="{name: 'creatives-statistics', params: { creative_id: item.creativeId } }"
                      >
                        <v-list-item-icon class="mr-3">
                          <v-icon v-text="icons.mdiChartLine"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ $t('CampaingCreativesDaily') }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-if="item.isVideo === true"
                        :to="{name: 'creatives-vtr-report', params: { creative_id: item.creativeId } }"
                      >
                        <v-list-item-icon class="mr-3">
                          <v-icon v-text="icons.mdiVideoOutline"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ $t('CreativeVTRStatistics') }}</v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </template>
          <template slot="body.append">
            <tr>
              <th>
                {{ $t('SelectedPeriod') }}
              </th>
              <th>
                {{ totals.currentPeriod.views }}
              </th>
              <th>
                {{ totals.currentPeriod.clicks }}
              </th>
              <th
                v-if="campaignType === 'video' && $checkAbility('CAN_VIEW_SHOWS')"
              >
                {{ totals.currentPeriod.shows }}
              </th>
              <th
                v-if="$checkAbility('CAN_VIEW_CTR')"
              >
                {{ totals.currentPeriod.ctr.toFixed(2) }}
              </th>
              <th
                v-if="campaignType === 'video'"
              >
                {{ totals.currentPeriod.vtr.toFixed(2) }}
              </th>
              <th>
              </th>
            </tr>
            <tr>
              <th>
                {{ $t('AllPeriod') }}
              </th>
              <th>
                {{ totals.totalPeriod.views }}
              </th>
              <th>
                {{ totals.totalPeriod.clicks }}
              </th>
              <th
                v-if="campaignType === 'video' && $checkAbility('CAN_VIEW_SHOWS')"
              >
                {{ totals.totalPeriod.shows }}
              </th>
              <th
                v-if="$checkAbility('CAN_VIEW_CTR')"
              >
                {{ totals.totalPeriod.ctr.toFixed(2) }}
              </th>
              <th
                v-if="campaignType === 'video'"
              >
                {{ totals.totalPeriod.vtr.toFixed(2) }}
              </th>
              <th>
              </th>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="showMessage"
      :timeout="timeout"
      :color="color"
    >
      {{ messageText }}
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiChartLine, mdiDotsVertical, mdiVideoOutline } from '@mdi/js'
import * as XLSX from 'xlsx-js-style'
import DateTimePicker from '../Components/DateTimePicker.vue'
import CampaignCreativesChart from './Components/CampaignCreativesListChartComponent.vue'

export default {
  components: {
    DateTimePicker,
    CampaignCreativesChart,
  },
  data() {
    return {
      search: '',
      showMessage: false,
      timeout: 5000,
      color: 'error',
      messageText: '',
      totals: {
        currentPeriod: {
          shows: 0,
          views: 0,
          ctr: 0.0,
          clicks: 0,
          vtr: 0.0,
        },
        totalPeriod: {
          shows: 0,
          views: 0,
          ctr: 0.0,
          clicks: 0,
          vtr: 0.0,
        },
      },
      isWeekly: false,
      isWeeklyTransfer: false,
      isVideoCampaign: false,
      brandSaftyData: [],
      excelStatistics: [],
      summaryByDates: {},
      widgetsData: [],
      dataFromDb: [],
      providers: [],
      providerId: null,
      advertiserId: null,
      advertisers: [],
      campaigns: [],
      campaignId: Number(this.$route.params.campaign_id),
      loadingFirst: false,
      loadingCampaign: false,
      campaignType: '',
      campaignName: '',
      headers: [],
      dateFrom: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1,
        new Date().getHours(),
        new Date().getMinutes(),
        new Date().getSeconds(),
      )
        .toISOString()
        .substr(0, 10),
      dateTo: new Date().toISOString().substr(0, 10),
      transferDateFrom: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1,
        new Date().getHours(),
        new Date().getMinutes(),
        new Date().getSeconds(),
      )
        .toISOString()
        .substr(0, 10),
      transferDateTo: new Date().toISOString().substr(0, 10),
      loading: false,
      icons: {
        mdiVideoOutline,
        mdiChartLine,
        mdiDotsVertical,
      },
    }
  },

  watch: {
    advertiserId() {
      this.getCampaings(false)
    },
    providerId() {
      this.getCampaings(false)
    },
    campaignId() {
      if (this.campaignId && this.campaigns.length > 0) {
        const camping = this.campaigns.find(c => c.id === this.campaignId)
        this.campaignType = camping.type_id
        this.campaignName = camping.name
      }
    },
  },

  mounted() {
    this.firstLoad()
  },

  methods: {
    firstLoad() {
      this.getAdvertisersOrProviders().then(() => {
        this.getCampaings(true).then(() => {
          this.getData()
        })
      })
    },
    exportToExcel() {
      const excelHeadersIndexes = [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
      ]

      const wb = XLSX.utils.book_new()
      const rows = []
      this.summaryByDates = []
      // eslint-disable-next-line prefer-const
      const sl = XLSX.utils.json_to_sheet(rows)
      //создаем динамические заголовки для нижней таблицы
      var headersForSummary = []

      headersForSummary.push({ text: this.$t('Creative'), value: 'creativeName' })
      headersForSummary.push({ text: this.$t('Shows'), value: 'views' })
      headersForSummary.push({ text: this.$t('References'), value: 'clicks' })
      if (this.$checkAbility('CAN_VIEW_UNIQ_VIEWS')) {
        headersForSummary.push({ text: this.$t('UniqUsers'), value: 'uniqViews' })
      }
      if (this.$checkAbility('CAN_VIEW_RF')) {
        headersForSummary.push({ text: this.$t('RF'), value: 'rf', toFixed: true })
      }
      if (this.$checkAbility('CAN_VIEW_CTR')) {
        headersForSummary.push({ text: this.$t('CTR'), value: 'ctr', toFixed: true })
      }
      if (this.campaignType === 'video') {
        if (this.$checkAbility('CAN_VIEW_SHOWS')) {
          headersForSummary.push({ text: this.$t('Views'), value: 'shows' })
        }
        headersForSummary.push({ text: 'VTR', value: 'vtr', toFixed: true })
      }
      //добавляем индексы
      for (let index = 0; index < headersForSummary.length; index++) {
        headersForSummary[index].excelHedaerIndex = excelHeadersIndexes[index]
      }

      // Рабочая область
      sl['!ref'] = 'A1:Z50'

      // соединение ячеек
      sl['!merges'] = [{ s: { r: 0, c: 10 }, e: { r: 0, c: 0 } }]

      // Ширина столбцов
      sl['!cols'] = [
        { width: 30 },
        { width: 20 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
      ]

      // верхняя шапка
      sl.A1 = {
        t: 's',
        v: `${this.$t('SummaryACData')}: ${this.campaignName}`,
        s: { fill: { fgColor: { rgb: 'E9E9E9' } } },
      }
      sl.A2 = {
        t: 's',
        v: this.$t('DateFrom'),
      }
      sl.A3 = {
        t: 's',
        v: this.$t('DateTo'),
      }

      sl.B2 = {
        t: 'd',
        v: this.dateFrom,
      }
      sl.B3 = {
        t: 'd',
        v: this.dateTo,
      }

      // шапка таблицы

      let headerRowIndex = 5

      headersForSummary.forEach(item => {
        sl[`${item.excelHedaerIndex}${headerRowIndex}`] = {
          t: 's',
          v: item.text,
          s: { fill: { fgColor: { rgb: 'E9E9E9' } } },
        }
      })

      // Заполняем таблицу через AoA

      const getVal = (ob, s) => s.split('.').reduce((o, k) => o?.[k], ob)
      const getType = ob => {
        if (typeof ob === 'number') {
          return 'n'
        }
        if (typeof ob === 'string') {
          return 's'
        }
      }

      let count = 6
      this.dataFromDb.forEach(element => {
        headersForSummary.forEach(item => {
          let value = getVal(element, item.value)
          if (item.toFixed) value = parseFloat(value.toFixed(2))
          sl[`${item.excelHedaerIndex}${count}`] = {
            t: getType(value),
            v: value,
          }
        })

        // eslint-disable-next-line no-plusplus
        count++
      })

      // Добавляем итоговые ячейки

      var headersForSummaryCurrentPeriod = []

      headersForSummaryCurrentPeriod.push({ text: this.$t('SelectedPeriod'), value: this.$t('SelectedPeriod') })
      headersForSummaryCurrentPeriod.push({ text: this.$t('Shows'), value: this.totals.currentPeriod.views })
      headersForSummaryCurrentPeriod.push({ text: this.$t('References'), value: this.totals.currentPeriod.clicks })
      if (this.$checkAbility('CAN_VIEW_UNIQ_VIEWS')) {
        headersForSummaryCurrentPeriod.push({ text: this.$t('UniqUsers'), value: this.totals.currentPeriod.uniqViews })
      }
      if (this.$checkAbility('CAN_VIEW_RF')) {
        headersForSummaryCurrentPeriod.push({ text: this.$t('RF'), value: this.totals.currentPeriod.rf, toFixed: true })
      }
      if (this.$checkAbility('CAN_VIEW_CTR')) {
        headersForSummaryCurrentPeriod.push({
          text: this.$t('CTR'),
          value: this.totals.currentPeriod.ctr,
          toFixed: true,
        })
      }
      if (this.campaignType === 'video') {
        if (this.$checkAbility('CAN_VIEW_SHOWS')) {
          headersForSummaryCurrentPeriod.push({ text: this.$t('Views'), value: this.totals.currentPeriod.shows })
        }
        headersForSummaryCurrentPeriod.push({ text: 'VTR', value: this.totals.currentPeriod.vtr, toFixed: true })
      }
      //добавляем индексы
      for (let index = 0; index < headersForSummaryCurrentPeriod.length; index++) {
        headersForSummaryCurrentPeriod[index].excelHedaerIndex = excelHeadersIndexes[index]
      }
      //заполняем поля с суммами
      headersForSummaryCurrentPeriod.forEach(item => {
        if (item.toFixed) item.value = parseFloat(item.value.toFixed(2))
        sl[`${item.excelHedaerIndex}${count}`] = {
          t: getType(item.value),
          v: item.value,
          s: { fill: { fgColor: { rgb: 'E9E9E9' } } },
        }
      })

      // eslint-disable-next-line no-plusplus
      count++

      var headersForSummaryAllPeriod = []

      headersForSummaryAllPeriod.push({ text: this.$t('SelectedPeriod'), value: this.$t('AllPeriod') })
      headersForSummaryAllPeriod.push({ text: this.$t('Shows'), value: this.totals.totalPeriod.views })
      headersForSummaryAllPeriod.push({ text: this.$t('References'), value: this.totals.totalPeriod.clicks })
      if (this.$checkAbility('CAN_VIEW_UNIQ_VIEWS')) {
        headersForSummaryAllPeriod.push({ text: this.$t('UniqUsers'), value: this.totals.totalPeriod.uniqViews })
      }
      if (this.$checkAbility('CAN_VIEW_RF')) {
        headersForSummaryAllPeriod.push({ text: this.$t('RF'), value: this.totals.totalPeriod.rf, toFixed: true })
      }
      if (this.$checkAbility('CAN_VIEW_CTR')) {
        headersForSummaryAllPeriod.push({ text: this.$t('CTR'), value: this.totals.totalPeriod.ctr, toFixed: true })
      }
      if (this.campaignType === 'video') {
        if (this.$checkAbility('CAN_VIEW_SHOWS')) {
          headersForSummaryAllPeriod.push({ text: this.$t('Views'), value: this.totals.totalPeriod.shows })
        }
        headersForSummaryAllPeriod.push({ text: 'VTR', value: this.totals.totalPeriod.vtr, toFixed: true })
      }
      //добавляем индексы
      for (let index = 0; index < headersForSummaryAllPeriod.length; index++) {
        headersForSummaryAllPeriod[index].excelHedaerIndex = excelHeadersIndexes[index]
      }
      //заполняем поля с суммами
      headersForSummaryAllPeriod.forEach(item => {
        if (item.toFixed) item.value = parseFloat(item.value.toFixed(2))
        sl[`${item.excelHedaerIndex}${count}`] = {
          t: getType(item.value),
          v: item.value,
          s: { fill: { fgColor: { rgb: 'E9E9E9' } } },
        }
      })

      // Добавляем к документу
      XLSX.utils.book_append_sheet(wb, sl, this.$t('SummarySheet'))

      // eslint-disable-next-line prefer-const

      const secondStage = () => {
        // Лист Итого
        const scs = XLSX.utils.json_to_sheet(rows)

        // Рабочая область
        scs['!ref'] = 'A1:Z50'

        // соединение ячеек
        scs['!merges'] = [{ s: { r: 0, c: 10 }, e: { r: 0, c: 0 } }]

        // Ширина столбцов
        scs['!cols'] = [
          { width: 30 },
          { width: 25 },
          { width: 20 },
          { width: 15 },
          { width: 15 },
          { width: 15 },
          { width: 15 },
          { width: 15 },
          { width: 15 },
          { width: 15 },
          { width: 15 },
        ]

        // верхняя шапка
        scs.A1 = {
          t: 's',
          v: `${this.$t('SummaryACData2')}: ${this.campaignName}`,
          s: { fill: { fgColor: { rgb: 'E9E9E9' } } },
        }
        scs.A2 = {
          t: 's',
          v: this.$t('DateFrom'),
        }
        scs.A3 = {
          t: 's',
          v: this.$t('DateTo'),
        }

        scs.B2 = {
          t: 'd',
          v: this.dateFrom,
        }
        scs.B3 = {
          t: 'd',
          v: this.dateTo,
        }

        // Суммы за периоды
        scs.B4 = {
          t: 's',
          v: this.$t('SelectedPeriod'),
          s: { fill: { fgColor: { rgb: 'E9E9E9' } } },
        }
        scs.C4 = {
          t: 's',
          v: this.$t('AllPeriod'),
          s: { fill: { fgColor: { rgb: 'E9E9E9' } } },
        }

        var headersForSummaryPeriod2 = []

        headersForSummaryPeriod2.push({
          text: this.$t('Shows'),
          totalsCurrent: this.totals.currentPeriod.views,
          totalsAll: this.totals.totalPeriod.views,
        })
        headersForSummaryPeriod2.push({
          text: this.$t('References'),
          totalsCurrent: this.totals.currentPeriod.clicks,
          totalsAll: this.totals.totalPeriod.clicks,
        })
        if (this.$checkAbility('CAN_VIEW_UNIQ_VIEWS')) {
          headersForSummaryPeriod2.push({
            text: this.$t('UniqUsers'),
            totalsCurrent: this.totals.currentPeriod.uniqViews,
            totalsAll: this.totals.totalPeriod.uniqViews,
          })
        }
        if (this.$checkAbility('CAN_VIEW_RF')) {
          headersForSummaryPeriod2.push({
            text: this.$t('RF'),
            totalsCurrent: this.totals.currentPeriod.rf,
            totalsAll: this.totals.totalPeriod.rf,
            toFixed: true,
          })
        }
        if (this.$checkAbility('CAN_VIEW_CTR')) {
          headersForSummaryPeriod2.push({
            text: this.$t('CTR'),
            totalsCurrent: this.totals.currentPeriod.ctr,
            totalsAll: this.totals.totalPeriod.ctr,
            toFixed: true,
          })
        }
        if (this.campaignType === 'video') {
          if (this.$checkAbility('CAN_VIEW_SHOWS')) {
            headersForSummaryPeriod2.push({
              text: this.$t('Views'),
              totalsCurrent: this.totals.currentPeriod.shows,
              totalsAll: this.totals.totalPeriod.shows,
            })
          }
          headersForSummaryPeriod2.push({
            text: 'VTR',
            totalsCurrent: this.totals.currentPeriod.vtr,
            totalsAll: this.totals.totalPeriod.vtr,
            toFixed: true,
          })
        }
        //добавляем индексы

        //заполняем поля с суммами
        let countsumm = 5
        let indexsumm = 'A'
        headersForSummaryPeriod2.forEach(item => {
          scs[`${indexsumm}${countsumm}`] = {
            t: 's',
            v: item.text,
          }
          countsumm = countsumm + 1
        })

        countsumm = 5
        indexsumm = 'B'
        headersForSummaryPeriod2.forEach(item => {
          if (item.toFixed) item.totalsCurrent = parseFloat(item.totalsCurrent.toFixed(2))
          scs[`${indexsumm}${countsumm}`] = {
            t: getType(item.totalsCurrent),
            v: item.totalsCurrent,
          }
          countsumm = countsumm + 1
        })

        countsumm = 5
        indexsumm = 'C'
        headersForSummaryPeriod2.forEach(item => {
          if (item.toFixed) item.totalsAll = parseFloat(item.totalsAll.toFixed(2))
          scs[`${indexsumm}${countsumm}`] = {
            t: getType(item.totalsAll),
            v: item.totalsAll,
          }
          countsumm = countsumm + 1
        })

        var headersForSummary2 = []

        headersForSummary2.push({ text: this.$t('Date'), value: '', valueIsIndex: true })
        headersForSummary2.push({ text: this.$t('Shows'), value: 'views' })
        headersForSummary2.push({ text: this.$t('References'), value: 'clicks' })
        if (this.$checkAbility('CAN_VIEW_UNIQ_VIEWS')) {
          headersForSummary2.push({ text: this.$t('UniqUsers'), value: 'uniqViews' })
        }
        if (this.$checkAbility('CAN_VIEW_RF')) {
          headersForSummary2.push({ text: this.$t('RF'), value: 'rf', toFixed: true })
        }
        if (this.$checkAbility('CAN_VIEW_CTR')) {
          headersForSummary2.push({ text: this.$t('CTR'), value: 'ctr', toFixed: true })
        }
        if (this.campaignType === 'video') {
          if (this.$checkAbility('CAN_VIEW_SHOWS')) {
            headersForSummary2.push({ text: this.$t('Views'), value: 'shows' })
          }
          headersForSummary2.push({ text: 'VTR', value: 'vtr', toFixed: true })
        }
        //добавляем индексы
        for (let index = 0; index < headersForSummary2.length; index++) {
          headersForSummary2[index].excelHedaerIndex = excelHeadersIndexes[index]
        }

        let headerRowIndex = countsumm

        headersForSummary2.forEach(item => {
          scs[`${item.excelHedaerIndex}${headerRowIndex}`] = {
            t: 's',
            v: item.text,
            s: { fill: { fgColor: { rgb: 'E9E9E9' } } },
          }
        })

        // Заполняем таблицу через AoA
        headerRowIndex = headerRowIndex + 1

        console.log(this.summaryByDates)

        for (const elementCs in this.summaryByDates) {
          headersForSummary2.forEach(item => {
            let value = this.summaryByDates[elementCs][item.value]
            if (item.valueIsIndex) value = elementCs
            if (item.toFixed) value = parseFloat(value.toFixed(2))
            scs[`${item.excelHedaerIndex}${headerRowIndex}`] = {
              t: getType(value),
              v: value,
            }
          })

          // eslint-disable-next-line no-plusplus
          headerRowIndex++
        }

        // Добавляем к документу
        XLSX.utils.book_append_sheet(wb, scs, this.$t('Summary'))

        // console.log(wb)

        // /* Export to file (start a download) */
        let dateString = `${this.dateFrom}-${this.dateTo}`
        if (this.dateFrom === null) {
          dateString = this.$t('DateTimePicker.AllPeriod')
        }
        XLSX.writeFile(wb, `${this.campaignId}_Creatives_report(${dateString}).xlsx`)
      }
      let counter = 0
      const firstStage = () => {
        // Создаем динамические листы для каждого креатива
        this.dataFromDb.forEach(element => {
          let data = []

          // eslint-disable-next-line prefer-const
          let totalData = { currentPeriod: {}, totalPeriod: {} }

          // полуачем подробную стату по каждому креативу
          const dateStart = this.dateFrom != null ? `${this.dateFrom}` : '1970-01-01'
          // eslint-disable-next-line operator-linebreak
          const dateFinish = this.dateTo != null ? `${this.dateTo}` : `${new Date().toISOString().substr(0, 10)}`
          const params = {
            params: {
              dateFrom: dateStart,
              dateTo: dateFinish,
              campaignIds: this.campaignId,
              userId: this.$getUserId(),
              providerId: this.providerId,
              creativeIds: element.creativeId,
            },
          }
          this.$http
            .get(`${this.$apiBaseURL}/creatives/details`, params)
            .then(response => {
              console.log(response)
              data = response.data !== null ? response.data : []

              // if (data.length > 0 && Object.keys(this.summaryByDates).length === 0) {
              //   data.forEach(item => {
              //     this.summaryByDates[item.statisticsDate.substr(0, 10)] = {
              //       shows: 0,
              //       views: 0,
              //       clicks: 0,
              //       uniqViews: 0,
              //       rf: 0,
              //       ctr: 0,
              //       vtr: 0.0,
              //       calcRf: 0,
              //       calcCtr: 0,
              //     }
              //   })
              // }
            })
            .then(() => {
              let countTotal = 0
              let tempCTR = 0.0
              let tempRF = 0.0
              totalData.currentPeriod.shows = 0
              totalData.currentPeriod.views = 0
              totalData.currentPeriod.clicks = 0
              totalData.currentPeriod.ctr = 0.0
              totalData.currentPeriod.rf = 0.0
              totalData.currentPeriod.uniqViews = 0
              totalData.currentPeriod.vtr = 0.0
              if (data.length > 0) {
                data.forEach(item => {
                  totalData.currentPeriod.shows += item.shows
                  totalData.currentPeriod.views += item.views
                  totalData.currentPeriod.clicks += item.clicks
                  totalData.currentPeriod.uniqViews += item.uniqViews
                  tempCTR += item.ctr
                  tempRF += item.rf
                  countTotal += 1
                  if (this.summaryByDates[this.moment(item.statisticsDate).format('YYYY-MM-DD')] === undefined) {
                    this.summaryByDates[this.moment(item.statisticsDate).format('YYYY-MM-DD')] = {
                      shows: 0,
                      views: 0,
                      clicks: 0,
                      uniqViews: 0,
                      rf: 0,
                      ctr: 0,
                      vtr: 0.0,
                      calcRf: 0,
                      calcCtr: 0,
                    }
                  }
                  this.summaryByDates[this.moment(item.statisticsDate).format('YYYY-MM-DD')].shows += item.shows
                  this.summaryByDates[this.moment(item.statisticsDate).format('YYYY-MM-DD')].views += item.views
                  this.summaryByDates[this.moment(item.statisticsDate).format('YYYY-MM-DD')].clicks += item.clicks
                  this.summaryByDates[this.moment(item.statisticsDate).format('YYYY-MM-DD')].uniqViews += item.uniqViews
                  this.summaryByDates[this.moment(item.statisticsDate).format('YYYY-MM-DD')].calcCtr += item.ctr
                  this.summaryByDates[this.moment(item.statisticsDate).format('YYYY-MM-DD')].calcRf += item.rf
                })
                if (tempCTR > 0.0) {
                  totalData.currentPeriod.ctr = tempCTR / countTotal
                } else {
                  totalData.currentPeriod.ctr = 0.0
                }
                if (tempRF > 0.0) {
                  totalData.currentPeriod.rf = tempRF / countTotal
                } else {
                  totalData.currentPeriod.rf = 0.0
                }
                totalData.currentPeriod.vtr = (totalData.currentPeriod.shows / totalData.currentPeriod.views) * 100

                // eslint-disable-next-line no-restricted-syntax, guard-for-in
                for (const item in this.summaryByDates) {
                  if (this.summaryByDates[item].calcCtr > 0) {
                    // eslint-disable-next-line no-param-reassign
                    this.summaryByDates[item].ctr = this.summaryByDates[item].calcCtr / countTotal
                  }
                  if (this.summaryByDates[item].calcRf > 0) {
                    // eslint-disable-next-line no-param-reassign
                    this.summaryByDates[item].rf = this.summaryByDates[item].calcRf / countTotal
                  }
                  if (this.summaryByDates[item].shows > 0) {
                    // eslint-disable-next-line no-param-reassign, operator-linebreak
                    this.summaryByDates[item].vtr =
                      (this.summaryByDates[item].shows / this.summaryByDates[item].views) * 100
                  }
                }
              }
            })
            .then(() => {
              const paramsTotal = {
                params: {
                  dateFrom: '1970-01-01',
                  dateTo: `${new Date().toISOString().substr(0, 10)}`,
                  campaignIds: this.campaignId,
                  userId: this.$getUserId(),
                  providerId: this.providerId,
                  creativeIds: element.creativeId,
                },
              }
              this.$http
                .get(`${this.$apiBaseURL}/creatives/details`, paramsTotal)
                .then(response => {
                  const tempData = response.data !== null ? response.data : []
                  let countTotal = 0
                  let tempCTR = 0.0
                  let tempRF = 0.0
                  totalData.totalPeriod.shows = 0
                  totalData.totalPeriod.views = 0
                  totalData.totalPeriod.clicks = 0
                  totalData.totalPeriod.ctr = 0.0
                  totalData.totalPeriod.rf = 0.0
                  totalData.totalPeriod.uniqViews = 0
                  totalData.totalPeriod.vtr = 0
                  if (tempData.length > 0) {
                    tempData.forEach(item => {
                      totalData.totalPeriod.shows += item.shows
                      totalData.totalPeriod.views += item.views
                      totalData.totalPeriod.clicks += item.clicks
                      totalData.totalPeriod.uniqViews += item.uniqViews
                      tempCTR += item.ctr
                      tempRF += item.rf
                      countTotal += 1
                    })
                    if (tempCTR > 0.0) {
                      totalData.totalPeriod.ctr = tempCTR / countTotal
                    } else {
                      totalData.totalPeriod.ctr = 0.0
                    }
                    if (tempRF > 0.0) {
                      totalData.totalPeriod.rf = tempRF / countTotal
                    } else {
                      totalData.totalPeriod.rf = 0.0
                    }
                    totalData.totalPeriod.vtr = (totalData.totalPeriod.shows / totalData.totalPeriod.views) * 100
                  }
                })
                .then(() => {
                  const cs = XLSX.utils.json_to_sheet(rows)

                  // Рабочая область
                  cs['!ref'] = 'A1:Z50'

                  // соединение ячеек
                  cs['!merges'] = [{ s: { r: 0, c: 3 }, e: { r: 0, c: 0 } }]

                  // Ширина столбцов
                  cs['!cols'] = [
                    { width: 30 },
                    { width: 25 },
                    { width: 20 },
                    { width: 15 },
                    { width: 15 },
                    { width: 15 },
                    { width: 15 },
                    { width: 15 },
                    { width: 15 },
                    { width: 15 },
                    { width: 15 },
                  ]

                  // верхняя шапка
                  cs.A1 = {
                    t: 's',
                    v: `Кратив: ${element.creativeName}`,
                    s: { fill: { fgColor: { rgb: 'E9E9E9' } } },
                  }
                  cs.A2 = {
                    t: 's',
                    v: this.$t('DateFrom'),
                  }
                  cs.A3 = {
                    t: 's',
                    v: this.$t('DateTo'),
                  }

                  cs.B2 = {
                    t: 'd',
                    v: this.dateFrom,
                  }
                  cs.B3 = {
                    t: 'd',
                    v: this.dateTo,
                  }

                  // Суммы за периоды
                  cs.B4 = {
                    t: 's',
                    v: this.$t('SelectedPeriod'),
                    s: { fill: { fgColor: { rgb: 'E9E9E9' } } },
                  }
                  cs.C4 = {
                    t: 's',
                    v: this.$t('AllPeriod'),
                    s: { fill: { fgColor: { rgb: 'E9E9E9' } } },
                  }

                  var headersForCreativeSummaryPeriod = []

                  headersForCreativeSummaryPeriod.push({
                    text: this.$t('Shows'),
                    totalsCurrent: totalData.currentPeriod.views,
                    totalsAll: totalData.totalPeriod.views,
                  })
                  headersForCreativeSummaryPeriod.push({
                    text: this.$t('References'),
                    totalsCurrent: totalData.currentPeriod.clicks,
                    totalsAll: totalData.totalPeriod.clicks,
                  })
                  if (this.$checkAbility('CAN_VIEW_UNIQ_VIEWS')) {
                    headersForCreativeSummaryPeriod.push({
                      text: this.$t('UniqUsers'),
                      totalsCurrent: totalData.currentPeriod.uniqViews,
                      totalsAll: totalData.totalPeriod.uniqViews,
                    })
                  }
                  if (this.$checkAbility('CAN_VIEW_RF')) {
                    headersForCreativeSummaryPeriod.push({
                      text: this.$t('RF'),
                      totalsCurrent: totalData.currentPeriod.rf,
                      totalsAll: totalData.totalPeriod.rf,
                      toFixed: true,
                    })
                  }
                  if (this.$checkAbility('CAN_VIEW_CTR')) {
                    headersForCreativeSummaryPeriod.push({
                      text: this.$t('CTR'),
                      totalsCurrent: totalData.currentPeriod.ctr,
                      totalsAll: totalData.totalPeriod.ctr,
                      toFixed: true,
                    })
                  }
                  if (this.campaignType === 'video') {
                    if (this.$checkAbility('CAN_VIEW_SHOWS')) {
                      headersForCreativeSummaryPeriod.push({
                        text: this.$t('Views'),
                        totalsCurrent: totalData.currentPeriod.shows,
                        totalsAll: totalData.totalPeriod.shows,
                      })
                    }
                    headersForCreativeSummaryPeriod.push({
                      text: 'VTR',
                      totalsCurrent: totalData.currentPeriod.vtr,
                      totalsAll: totalData.totalPeriod.vtr,
                      toFixed: true,
                    })
                  }
                  //добавляем индексы

                  //заполняем поля с суммами
                  let countsumm = 5
                  let indexsumm = 'A'
                  headersForCreativeSummaryPeriod.forEach(item => {
                    cs[`${indexsumm}${countsumm}`] = {
                      t: 's',
                      v: item.text,
                    }
                    countsumm = countsumm + 1
                  })

                  countsumm = 5
                  indexsumm = 'B'
                  headersForCreativeSummaryPeriod.forEach(item => {
                    if (item.toFixed) item.totalsCurrent = parseFloat(item.totalsCurrent.toFixed(2))
                    cs[`${indexsumm}${countsumm}`] = {
                      t: getType(item.totalsCurrent),
                      v: item.totalsCurrent,
                    }
                    countsumm = countsumm + 1
                  })

                  countsumm = 5
                  indexsumm = 'C'
                  headersForCreativeSummaryPeriod.forEach(item => {
                    if (item.toFixed) item.totalsAll = parseFloat(item.totalsAll.toFixed(2))
                    cs[`${indexsumm}${countsumm}`] = {
                      t: getType(item.totalsAll),
                      v: item.totalsAll,
                    }
                    countsumm = countsumm + 1
                  })

                  var headersForSummaryByDate = []

                  headersForSummaryByDate.push({ text: this.$t('Date'), value: 'statisticsDate', isDate: true })
                  headersForSummaryByDate.push({ text: this.$t('Shows'), value: 'views' })
                  headersForSummaryByDate.push({ text: this.$t('References'), value: 'clicks' })
                  if (this.$checkAbility('CAN_VIEW_UNIQ_VIEWS')) {
                    headersForSummaryByDate.push({ text: this.$t('UniqUsers'), value: 'uniqViews' })
                  }
                  if (this.$checkAbility('CAN_VIEW_RF')) {
                    headersForSummaryByDate.push({ text: this.$t('RF'), value: 'rf', toFixed: true })
                  }
                  if (this.$checkAbility('CAN_VIEW_CTR')) {
                    headersForSummaryByDate.push({ text: this.$t('CTR'), value: 'ctr', toFixed: true })
                  }
                  if (this.campaignType === 'video') {
                    if (this.$checkAbility('CAN_VIEW_SHOWS')) {
                      headersForSummaryByDate.push({ text: this.$t('Views'), value: 'shows' })
                    }
                    headersForSummaryByDate.push({ text: 'VTR', value: 'vtr', toFixed: true })
                  }
                  //добавляем индексы
                  for (let index = 0; index < headersForSummaryByDate.length; index++) {
                    headersForSummaryByDate[index].excelHedaerIndex = excelHeadersIndexes[index]
                  }

                  let headerRowIndex = countsumm

                  headersForSummaryByDate.forEach(item => {
                    cs[`${item.excelHedaerIndex}${headerRowIndex}`] = {
                      t: 's',
                      v: item.text,
                      s: { fill: { fgColor: { rgb: 'E9E9E9' } } },
                    }
                  })

                  // Заполняем таблицу через AoA
                  headerRowIndex = headerRowIndex + 1

                  console.log(this.summaryByDates)

                  data.forEach(element => {
                    headersForSummaryByDate.forEach(item => {
                      let value = getVal(element, item.value)
                      if (item.toFixed) value = parseFloat(value.toFixed(2))
                      if (item.isDate) value = this.moment(value).format('YYYY-MM-DD')
                      cs[`${item.excelHedaerIndex}${headerRowIndex}`] = {
                        t: getType(value),
                        v: value,
                      }
                    })

                    // eslint-disable-next-line no-plusplus
                    headerRowIndex++
                  })

                  // Добавляем к документу
                  let sheetName = `${element.creativeId} - ${element.creativeName}`
                  sheetName = sheetName.replaceAll('[*/\\?\\[\\]]', '')
                  sheetName = sheetName.length > 31 ? sheetName.substring(0, 30) : sheetName
                  XLSX.utils.book_append_sheet(wb, cs, sheetName)
                  counter += 1
                  if (this.dataFromDb.length === counter) secondStage()
                })
            })
        })
      }

      firstStage()
    },
    goToDailyReport(id) {
      this.$router.push({ name: 'creatives-statistics', params: { creative_id: id } })
    },
    goToVTRReport(id) {
      this.$router.push({ name: 'creatives-vtr-report', params: { creative_id: id } })
    },
    getData() {
      if (!this.campaignId) {
        this.messageText = this.$t('EmptyCampaignId')
        this.showMessage = true

        return
      }
      this.loading = true
      this.transferDateFrom = this.dateFrom
      this.transferDateTo = this.dateTo
      this.isWeeklyTransfer = this.isWeekly
      const dateStart = this.dateFrom != null ? `${this.dateFrom}` : '1970-01-01'
      // eslint-disable-next-line operator-linebreak
      const dateFinish = this.dateTo != null ? `${this.dateTo}` : `${new Date().toISOString().substr(0, 10)}`

      const params = {
        params: {
          dateFrom: dateStart,
          dateTo: dateFinish,
          campaignIds: this.campaignId,
          userId: this.$getUserId(),
          providerId: this.providerId,
        },
      }

      // this.$http.get('/creatives/list/table-data', params)
      this.$http
        .get(`${this.$apiBaseURL}/creatives`, params)
        .then(response => {
          this.dataFromDb = response.data !== null ? response.data : []
          this.headers = []

          this.headers.push({ text: this.$t('Creative'), value: 'creativeName' })
          this.headers.push({ text: this.$t('Shows'), value: 'views' })
          this.headers.push({ text: this.$t('References'), value: 'clicks' })
          if (this.campaignType === 'video' && this.$checkAbility('CAN_VIEW_SHOWS')) {
            this.headers.push({ text: this.$t('Views'), value: 'shows' })
          }
          if (this.$checkAbility('CAN_VIEW_CTR')) {
            this.headers.push({ text: this.$t('CTR'), value: 'ctr' })
          }
          if (this.campaignType === 'video') {
            this.headers.push({ text: 'VTR', value: 'vtr' })
          }
          this.headers.push({ text: this.$t('Reports'), value: 'id' })

          let count = 0
          let tempCTR = 0.0
          let tempRF = 0.0
          this.totals.currentPeriod.shows = 0
          this.totals.currentPeriod.views = 0
          this.totals.currentPeriod.clicks = 0
          this.totals.currentPeriod.ctr = 0.0
          this.totals.currentPeriod.rf = 0.0
          this.totals.currentPeriod.uniqViews = 0
          if (this.dataFromDb.length > 0) {
            this.dataFromDb.forEach(item => {
              this.totals.currentPeriod.shows += item.shows
              this.totals.currentPeriod.views += item.views
              this.totals.currentPeriod.clicks += item.clicks
              this.totals.currentPeriod.uniqViews += item.uniqViews
              tempCTR += item.ctr
              tempRF += item.rf
              count += 1
            })
            if (tempCTR > 0.0) {
              this.totals.currentPeriod.ctr = tempCTR / count
            } else {
              this.totals.currentPeriod.ctr = 0.0
            }
            if (tempRF > 0.0) {
              this.totals.currentPeriod.rf = tempRF / count
            } else {
              this.totals.currentPeriod.rf = 0.0
            }
          }
          this.totals.currentPeriod.vtr = (this.totals.currentPeriod.shows / this.totals.currentPeriod.views) * 100

          this.loading = false
        })
        .then(() => {
          const paramsTotal = {
            params: {
              dateFrom: '1970-01-01',
              dateTo: `${new Date().toISOString().substr(0, 10)}`,
              campaignIds: this.campaignId,
              userId: this.$getUserId(),
              providerId: this.providerId,
            },
          }
          this.$http.get(`${this.$apiBaseURL}/creatives`, paramsTotal).then(responseTotal => {
            const tempData = responseTotal.data !== null ? responseTotal.data : []
            let count = 0
            let tempCTR = 0.0
            let tempRF = 0.0
            this.totals.totalPeriod.shows = 0
            this.totals.totalPeriod.views = 0
            this.totals.totalPeriod.clicks = 0
            this.totals.totalPeriod.ctr = 0.0
            this.totals.totalPeriod.rf = 0.0
            this.totals.totalPeriod.uniqViews = 0
            if (tempData.length > 0) {
              tempData.forEach(item => {
                this.totals.totalPeriod.shows += item.shows
                this.totals.totalPeriod.views += item.views
                this.totals.totalPeriod.clicks += item.clicks
                this.totals.totalPeriod.uniqViews += item.uniqViews
                tempCTR += item.ctr
                tempRF += item.rf
                count += 1
              })
              if (tempCTR > 0.0) {
                this.totals.totalPeriod.ctr = tempCTR / count
              } else {
                this.totals.totalPeriod.ctr = 0.0
              }
              if (tempRF > 0.0) {
                this.totals.totalPeriod.rf = tempRF / count
              } else {
                this.totals.totalPeriod.rf = 0.0
              }
              this.totals.totalPeriod.vtr = (this.totals.totalPeriod.shows / this.totals.totalPeriod.views) * 100
            }
          })
        })
    },
    getAdvertisersOrProviders() {
      this.loadingFirst = true

      if (!this.$checkRole('PROVIDERS')) {
        return this.$http
          .get(`${this.$apiBaseURL}/publisher/filter/adv-users`, { params: { userId: this.$getUserId() } })
          .then(response => {
            this.advertisers = response.data
            if (this.firstLoad === true) {
              this.advertiserId = null
            }

            this.loadingFirst = false
          })
      }

      return this.$http
        .get(`${this.$apiBaseURL}/campaigns/filter/providers`, { params: { userId: this.$getUserId() } })
        .then(response => {
          this.providers = response.data

          this.loadingFirst = false
        })
    },
    getCampaings(isFirstLoad) {
      this.loadingCampaign = true
      if (!this.$checkRole('PROVIDERS')) {
        return this.$http
          .get(`${this.$apiBaseURL}/campaigns/filter/campaigns`, {
            params: { userId: this.$getUserId(), advertiserId: this.advertiserId },
          })
          .then(response => {
            this.campaigns = response.data !== null ? response.data : []
            if (isFirstLoad) {
              if (!this.campaignId) {
                if (this.campaigns.length > 0) {
                  this.campaignId = this.campaigns[0].id
                } else {
                  this.campaignId = null
                }
              }
            } else if (this.campaigns.length > 0) {
              this.campaignId = this.campaigns[0].id
            } else {
              this.campaignId = null
            }

            this.loadingCampaign = false
          })
          .catch(error => {
            // TODO: Next Update - Show notification
            this.showMessage = true
            this.messageText = error
          })
      }

      return this.$http
        .get(`${this.$apiBaseURL}/campaigns/filter/campaigns`, {
          params: { userId: this.$getUserId(), providerIds: this.providerId },
        })
        .then(response => {
          this.campaigns = response.data !== null ? response.data : []
          if (isFirstLoad) {
            if (!this.campaignId) {
              if (this.campaigns.length > 0) {
                this.campaignId = this.campaigns[0].id
              } else {
                this.campaignId = null
              }
            }
          } else if (this.campaigns.length > 0) {
            this.campaignId = this.campaigns[0].id
          } else {
            this.campaignId = null
          }

          this.loadingCampaign = false
        })
        .catch(error => {
          // TODO: Next Update - Show notification
          this.showMessage = true
          this.messageText = error
        })
    },
  },
}
</script>
