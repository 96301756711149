<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="12"
      >
        <v-skeleton-loader
          v-show="loading === true"
          type="image"
        ></v-skeleton-loader>
        <div v-show="loading === false">
          <div v-show="data.length === 0">
            <v-alert
              outlined
              color="warning"
              class="d-flex justify-center"
              height="250"
              text
            >
              {{ $t('NoPlotDataAlert') }}
            </v-alert>
          </div>
          <div v-show="data.length > 0">
            <vue-apex-charts
              :options="chartOptions"
              :series="series"
            />
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    data: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      search: '',
      calories: '',
      loading: true,
      series: [],
      chartOptions: {
        chart: {
          type: 'donut',
          height: 250,
        },
        legend: {
          position: 'right',
          offsetX: 0,
          offsetY: 0,
        },
      },
    }
  },
  watch: {
    data() {
      this.getData()
    },
  },

  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.loading = true
      const tempSeries = []
      const tempLabels = []
      this.data.forEach(item => {
        tempSeries.push(item.clicks)
        tempLabels.push(item.creativeName)
      })
      console.log()
      this.series = tempSeries
      this.chartOptions = {
        chart: {
          type: 'donut',
          height: 305,
        },
        pie: {
          donut: {
            size: '70%',
            labels: {
              show: true,
              name: {
                fontSize: '14px',
                offsetY: 25,
              },
              value: {
                fontSize: '2.125rem',
                fontWeight: 600,

                offsetY: -15,
                formatter(value) {
                  return `${value}k`
                },
              },
              total: {
                show: true,
                label: 'Weekly Visits',
                color: 'rgba(94, 86, 105, 0.68)',
                formatter(value) {
                  return `${value.globals.seriesTotals.reduce((total, num) => total + num)}k`
                },
              },
            },
          },
        },
        labels: tempLabels,
        colors: ['#e0cffe', '#b992fe', '#ab7efd', '#9971e2', '#8d66d6', '#835fc6', '#704db5', '#6443a5'],
      }
      this.loading = false
    },
  },
}
</script>
<style lang="css">
.apexcharts-toolbar {
  display: none;
}
</style>
